// src/hooks/useNotifications.ts
import { useState, useEffect, useCallback } from 'react';
import { notificationService } from '../services/NotificationService';

export const useNotifications = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

 // Modified useEffect to also get token if notifications are already enabled
 useEffect(() => {
  const initializeNotifications = async () => {
    if (Notification.permission === 'granted') {
      setIsEnabled(true);
      setLoading(true);
      try {
        const fcmToken = await notificationService.getExistingToken();
        if (fcmToken) {
          setToken(fcmToken);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to get existing token');
      } finally {
        setLoading(false);
      }
    }
  };

  initializeNotifications();
}, []);

  const enableNotifications = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const fcmToken = await notificationService.enableNotifications();
      if (fcmToken) {
        setToken(fcmToken);
        setIsEnabled(true);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to enable notifications');
    } finally {
      setLoading(false);
    }
  }, []);

  return { isEnabled, token, error, enableNotifications, loading };
};