// Polyfill for the global object
if (typeof (window as any).global === 'undefined') {
  (window as any).global = window;
}

// Polyfill for the process object
if (typeof (window as any).process === 'undefined') {
  (window as any).process = { env: {} };
}

// Polyfill for Buffer
if (typeof (window as any).Buffer === 'undefined') {
  (window as any).Buffer = {
    isBuffer: () => false,
  };
}