import React, { useState, useEffect, useRef, FormEvent } from 'react';
import { Auth } from 'aws-amplify';

// Interface for form data
interface FormDataType {
  firstName: string;
  lastName: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

// Google Maps API key
const GOOGLE_MAPS_API_KEY = 'AIzaSyBsgqLGDBzzs6kLpgGiwS2p_Nhw5se7MiI';

// Load Google Maps script
const loadScript = (url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${url}"]`)) {
      resolve();
      return;
    }
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
    document.body.appendChild(script);
  });
};

const GoogleMapsAddressForm: React.FC = () => {
  const [formData, setFormData] = useState<FormDataType>({
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const addressInputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  // Fetch user attributes on mount
  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const attributes = await Auth.userAttributes(user);
        const nameAttr = attributes.find(attr => attr.Name === 'name');
        const addressAttr = attributes.find(attr => attr.Name === 'address');

        const nameParts = nameAttr ? nameAttr.Value.split(' ') : ['', ''];
        setFormData(prevData => ({
          ...prevData,
          firstName: nameParts[0] || '',
          lastName: nameParts[1] || '',
        }));

        if (addressAttr) {
          const addressValue = JSON.parse(addressAttr.Value);
          setFormData(prevData => ({
            ...prevData,
            address: addressValue.street || '',
            address2: addressValue.address2 || '',
            city: addressValue.city || '',
            state: addressValue.state || '',
            zip: addressValue.postal_code || '',
            country: addressValue.country || '',
          }));
        }

        setUserId(user.username);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };

    fetchUserAttributes();
  }, []);

  // Load Google Maps script and initialize Autocomplete
  useEffect(() => {
    const initializeAutocomplete = () => {
      if (!addressInputRef.current) return;

      autocompleteRef.current = new window.google.maps.places.Autocomplete(addressInputRef.current, {
        types: ['address'],
        componentRestrictions: { country: 'us' },
        fields: ['address_component', 'formatted_address'],
      });

      autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`
    )
      .then(() => {
        initializeAutocomplete();
      })
      .catch((err) => {
        console.error('Error loading Google Maps script:', err);
      });

    // Cleanup listener on unmount
    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
      }
    };
  }, []);

  // Handle place selection from Autocomplete
  const handlePlaceSelect = () => {
    const place = autocompleteRef.current?.getPlace();
    if (!place || !place.address_components) return;

    const addressComponents = place.address_components;

    const getComponent = (type: string): string => {
      const component = addressComponents.find((comp) => comp.types.includes(type));
      return component ? component.long_name : '';
    };

    setFormData((prevData) => ({
      ...prevData,
      address: place.formatted_address || '',
      city: getComponent('locality') || getComponent('sublocality') || '',
      state: getComponent('administrative_area_level_1') || '',
      zip: getComponent('postal_code') || '',
      country: getComponent('country') || '',
    }));
  };

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate address selection
    if (!formData.address) {
      alert('Please select an address from the suggestions.');
      return;
    }

    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const name = `${formData.firstName} ${formData.lastName}`;
      const address = JSON.stringify({
        street: formData.address,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        postal_code: formData.zip,
      });

      await Auth.updateUserAttributes(user, {
        name,
        address,
      });

      alert('Address saved successfully!');
    } catch (error) {
      console.error('Error updating user attributes:', error);
      alert('Failed to save address. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.formWrapper}>
        <h2 style={styles.heading}>Address</h2>
        <form onSubmit={handleFormSubmit} style={styles.form}>
          {/* First and Last Name */}
          <div style={styles.row}>
            <div style={styles.halfColumn}>
              <label style={styles.label}>
                First Name
                <input
                  type="text"
                  name="firstName"
                  required
                  style={styles.input}
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </label>
            </div>
            <div style={styles.halfColumn}>
              <label style={styles.label}>
                Last Name
                <input
                  type="text"
                  name="lastName"
                  required
                  style={styles.input}
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </label>
            </div>
          </div>

          {/* Address */}
          <label style={styles.label}>
            Street Address
            <input
              type="text"
              name="address"
              ref={addressInputRef}
              required
              style={styles.input}
              placeholder="Street Address"
              value={formData.address}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value.length < 3) {
                  setFormData((prevData) => ({
                    ...prevData,
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                  }));
                }
              }}
            />
          </label>

          {/* Address 2 */}
          <label style={styles.label}>
            Apartment, suite, etc. (optional)
            <input
              type="text"
              name="address2"
              style={styles.input}
              placeholder="Apartment, suite, etc. (optional)"
              value={formData.address2}
              onChange={handleInputChange}
            />
          </label>

          {/* City, State, Zip */}
          <div style={styles.row}>
            <div style={styles.thirdColumn}>
              <label style={styles.label}>
                City
                <input
                  type="text"
                  name="city"
                  required
                  style={styles.input}
                  placeholder="City"
                  value={formData.city}
                  onChange={handleInputChange}
                />
              </label>
            </div>
            <div style={styles.thirdColumn}>
              <label style={styles.label}>
                State
                <input
                  type="text"
                  name="state"
                  required
                  style={styles.input}
                  placeholder="State"
                  value={formData.state}
                  onChange={handleInputChange}
                />
              </label>
            </div>
            <div style={styles.thirdColumn}>
              <label style={styles.label}>
                Zip code
                <input
                  type="text"
                  name="zip"
                  required
                  style={styles.input}
                  placeholder="Zip code"
                  value={formData.zip}
                  onChange={handleInputChange}
                />
              </label>
            </div>
          </div>

          {/* Country */}
          <label style={styles.label}>
            Country
            <input
              type="text"
              name="country"
              required
              style={styles.input}
              placeholder="Country"
              value={formData.country}
              onChange={handleInputChange}
            />
          </label>

          {/* Save Button */}
          <div style={styles.buttonContainer}>
            <button type="submit" style={styles.button} disabled={loading}>
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Inline CSS styles for simplicity with responsiveness
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f9f9f9',
  },
  formWrapper: {
    maxWidth: '600px',
    margin: '0 auto',
  },
  heading: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginBottom: '15px',
  },
  halfColumn: {
    flex: '1 1 45%',
    minWidth: '200px',
  },
  thirdColumn: {
    flex: '1 1 30%',
    minWidth: '150px',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    fontSize: '14px',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginTop: '5px',
  },
  buttonContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },
  button: {
    padding: '12px 24px',
    fontSize: '16px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  },
};

// Media queries for responsiveness

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mediaStyles = `
@media (max-width: 600px) {
  .row {
    flex-direction: column;
  }
  .halfColumn, .thirdColumn {
    flex: 1 1 100%;
    min-width: 100%;
  }
  .buttonContainer {
    text-align: center;
  }
}
`;

// Inject media styles into the document

export default GoogleMapsAddressForm;