import React, { useState, useEffect } from 'react';
import { Trash2 } from 'lucide-react';
import config from '../swift-config';

declare global {
  interface Window {
    isArticlePage: () => Promise<boolean>;
  }
}

const isArticlePage = async (): Promise<boolean> => {
  return window.isArticlePage();
};


// Configure Amplify
let currentConfig;
if (window.location.hostname === "localhost") {
  console.log("Using localhost config");
  currentConfig = config["dev.summitdaily.com"];
} else {
  console.log("Using config for", window.location.hostname);
  currentConfig = config[window.location.hostname];
}

const pageviewPath = `${currentConfig?.Auth?.pubID}_pageViews`;

console.log('currentConfig pubID:', currentConfig.Auth.pubID);
interface Pageview {
  datetime: number;
  title: string;
  url: string;
}

const syncLocalPageviewsToDb = async (pageviews: Pageview[]) => {
  // Placeholder function to sync localStorage pageviews to the user's profile in the database
  // Replace this with the actual API call or DB update logic
  console.log('Syncing pageviews to database:', pageviews);
};

const PageviewHistory: React.FC = () => {
  const [pageviews, setPageviews] = useState<Pageview[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      const isArticle = await isArticlePage();
      console.log('isArticle:', isArticle); // Debug log
      const storedPageviews = JSON.parse(localStorage.getItem(pageviewPath) || '[]');
      console.log('storedPageviews:', storedPageviews); // Debug log
      setPageviews(storedPageviews);

      const syncWithDatabase = async () => {
        const isSynced = await checkDbSyncFlag();
        if (!isSynced) {
          await syncLocalPageviewsToDb(storedPageviews);
          await setDbSyncFlag(true);
        }
      };
      syncWithDatabase();
    };
    fetchData();
  }, []); // Add empty dependency array to run only once

  useEffect(() => {
    // Update paginatedPageviews whenever pageviews or currentPage changes
    setPaginatedPageviews(pageviews.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    ));
  }, [pageviews, currentPage]);

  const togglePageview = async (pageviewId: number) => {
    setPageviews((prev) => {
      const updatedPageviews = prev.filter((pageview) => pageview.datetime !== pageviewId);
      // Update localStorage
      localStorage.setItem(pageviewPath, JSON.stringify(updatedPageviews));
      // Sync with the user profile in the database
      syncLocalPageviewsToDb(updatedPageviews);
      return updatedPageviews;
    });
  };

  const checkDbSyncFlag = async (): Promise<boolean> => {
    // Placeholder function to check if the pageviews have been synced to DB
    // Replace this with the actual API call or DB query
    return false; // Assume false initially for demonstration
  };

  const setDbSyncFlag = async (flag: boolean) => {
    // Placeholder function to set the sync flag in the database
    // Replace with actual implementation
    console.log('Setting sync flag to:', flag);
  };

  const [paginatedPageviews, setPaginatedPageviews] = useState<Pageview[]>([]);

  const goToPageview = (url: string) => {
    window.location.href = url;
  };

  const totalPages = Math.ceil(pageviews.length / itemsPerPage);

  return (
    <div className="tw-max-w-2xl tw-mx-auto tw-bg-white tw-p-8 tw-rounded tw-shadow">
      <h2 className="tw-text-2xl tw-font-bold tw-mb-4">Reading History</h2>
      {pageviews.length === 0 ? (
        <p className="tw-text-gray-500">No pageviews yet. Start browsing pages to see them here!</p>
      ) : (
        <>
          <ul className="tw-pl-2">
            {paginatedPageviews.map((pageview) => (
              <li key={pageview.datetime} className="tw-flex tw-items-center tw-justify-between tw-py-2 tw-border-b">
                <span 
                  className="tw-cursor-pointer tw-hover:text-blue-600"
                  onClick={() => goToPageview(pageview.url)}
                >
                  {pageview.title}
                </span>
                <button
                  onClick={() => togglePageview(pageview.datetime)}
                  className="tw-p-2 tw-rounded-full tw-bg-transparent"
                >
                  <Trash2 size={24} />
                </button>
              </li>
            ))}
          </ul>
          {totalPages > 1 && (
          <div className="tw-mt-4 tw-flex tw-justify-between">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="tw-px-3 tw-py-1 tw-bg-blue-500 tw-text-white tw-rounded tw-disabled:bg-gray-300"
            >
              Previous
            </button>
            <span>{currentPage} / {totalPages}</span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="tw-px-3 tw-py-1 tw-bg-blue-500 tw-text-white tw-rounded tw-disabled:bg-gray-300"
            >
              Next
            </button>
          </div>
          )}
        </>
      )}
    </div>
  );
};

const PageviewTracker: React.FC = () => {
  useEffect(() => {
    const trackPageview = async () => {
      const isArticle = await isArticlePage();
      if (isArticle) {
        // strip everything after | in the title
        const title = document.title.split('|')[0].trim();
        const pageview: Pageview = {
          datetime: Date.now(),
          title: title,
          url: window.location.href,
        };
        const storedPageviews = JSON.parse(localStorage.getItem(pageviewPath) || '[]');
        const updatedPageviews = [...storedPageviews, pageview];
        const isPageviewExists = storedPageviews.some((pv: { url: string; }) => pv.url === pageview.url); 
        if (isPageviewExists) return; // check if the pageview is already in the list and don't add it if it is
        localStorage.setItem(pageviewPath, JSON.stringify(updatedPageviews));
        await syncLocalPageviewsToDb(updatedPageviews);
      }
    };
    trackPageview();
  }, []);

  return null; // This component does not render anything
};

export { PageviewHistory, PageviewTracker };