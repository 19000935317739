import React, { useState, useEffect, useRef } from "react";
import config from "../swift-config";
import { Bookmark, BookMarked, Trash2 } from "lucide-react";

// Configure Amplify
let currentConfig;
if (window.location.hostname === "localhost") {
  console.log("Using localhost config");
  currentConfig = config["dev.summitdaily.com"];
} else {
  console.log("Using config for", window.location.hostname);
  currentConfig = config[window.location.hostname];
}

console.log("currentConfig pubID:", currentConfig.Auth.pubID);

const pubID = currentConfig?.Auth?.pubID;
const bookmarkPath = `${pubID}_bookmarkedPosts`;

interface BookmarkData {
  postid: number;
  datetime: number;
  posttitle: string;
  postlink: string;
}

const Bookmarks: React.FC = () => {
  const [bookmarkedHeadlines, setBookmarkedHeadlines] = useState<BookmarkData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const hasSynced = useRef(false);

  useEffect(() => {
    const loadAndSyncBookmarks = async () => {
      try {
        // Load bookmarks from localStorage
        const storedBookmarks = localStorage.getItem(bookmarkPath);
        let parsedBookmarks: BookmarkData[] = [];
        if (storedBookmarks) {
          parsedBookmarks = JSON.parse(storedBookmarks);
          setBookmarkedHeadlines(parsedBookmarks);
          console.log("Loaded bookmarks:", parsedBookmarks);
        }

        if (!hasSynced.current) {
          const isSynced = await checkDbSyncFlag();
          if (!isSynced) {
            await syncLocalBookmarksToDb(parsedBookmarks);
            await setDbSyncFlag(true);
            hasSynced.current = true;
            console.log("Synced bookmarks to database.");
          }
        }

      } catch (error) {
        console.error("Error loading or syncing bookmarks:", error);
      }
    };

    loadAndSyncBookmarks();
  }, []); // Empty dependency array ensures this runs once on mount

  const deleteBookmark = (postid: number) => {
    const updatedBookmarks = bookmarkedHeadlines.filter((bookmark) => bookmark.postid !== postid);
    setBookmarkedHeadlines(updatedBookmarks);
    localStorage.setItem(bookmarkPath, JSON.stringify(updatedBookmarks));
    // Update the state on the ToggleBookmarkIcon component
    const bookmarkIcon = document.querySelector(`.bookmarktoggleicon[data-postid="${postid}"]`);
    if (bookmarkIcon) {
      bookmarkIcon.classList.remove("bookmarked");
    }
  };

  const checkDbSyncFlag = async (): Promise<boolean> => {
    // TODO: Implement actual logic to check if bookmarks are synced
    // For example, fetch from your backend or check a flag in localStorage
    return false; // Temporarily returning false for demonstration
  };

  const setDbSyncFlag = async (flag: boolean) => {
    // TODO: Implement actual logic to set the sync flag
    // For example, update your backend or set a flag in localStorage
    console.log("Setting sync flag to:", flag);
  };

  const syncLocalBookmarksToDb = async (bookmarks: BookmarkData[]) => {
    // TODO: Implement actual logic to sync bookmarks to the database
    // For example, make an API call to your backend
    console.log("Syncing bookmarks to database:", bookmarks);
  };


  const totalPages = Math.ceil(bookmarkedHeadlines.length / itemsPerPage);
  const paginatedHeadlines = bookmarkedHeadlines.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const goToArticle = (url: string) => {
    window.location.href = url;
  };

  return (
    <div className="tw-max-w-2xl tw-mx-auto tw-bg-white tw-p-8 tw-rounded tw-shadow">
      <h2 className="tw-text-2xl tw-font-bold tw-mb-4">Bookmarks</h2>
      {bookmarkedHeadlines.length === 0 ? (
        <p className="tw-text-gray-500">No bookmarks yet. Start bookmarking articles to see them here!</p>
      ) : (
        <>
          <ul className="tw-pl-2">
            {paginatedHeadlines.map((bookmark) => (
              <li key={bookmark.postid} className="tw-flex tw-items-center tw-justify-between tw-py-2 tw-border-b">
                <span
                  className="tw-cursor-pointer tw-hover:text-blue-600"
                  onClick={() => goToArticle(bookmark.postlink)}
                >
                  {bookmark.posttitle}
                </span>
                <button
                  onClick={() => deleteBookmark(bookmark.postid)}
                  className="tw-p-2 tw-rounded-full tw-bg-transparent"
                >
                  <Trash2 size={24} />
                </button>
              </li>
            ))}
          </ul>
          {/* // if the total pages is greater than 1, show the pagination buttons */}
          {totalPages > 1 && (
            <div className="tw-mt-4 tw-flex tw-justify-between">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="tw-px-3 tw-py-1 tw-bg-blue-500 tw-text-white tw-rounded tw-disabled:bg-gray-300"
              >
                Previous
              </button>
              <span>
                {currentPage} / {totalPages}
              </span>
              <button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="tw-px-3 tw-py-1 tw-bg-blue-500 tw-text-white tw-rounded tw-disabled:bg-gray-300"
              >
                Next
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};


const toggleBookmarkOnArticlePage = (
  bookmarkPath: string,
  e: React.MouseEvent,
  postid: number,
  posttitle?: string,
  postlink?: string
) => {
  console.log("Toggling bookmark...");
  console.log("Event target:", e.target);
  console.log("Post ID:", postid);

  const bookmarkIcon = (e.target as HTMLElement).closest(".bookmarktoggleicon");
  if (!bookmarkIcon) return;
  console.log("bookmarkIcon:", bookmarkIcon);
  console.dir(bookmarkIcon);

  if (bookmarkIcon) {
    const storedBookmarks: BookmarkData[] = JSON.parse(localStorage.getItem(bookmarkPath) || "[]");
    let updatedBookmarks: BookmarkData[];

    if (storedBookmarks.some((bookmark) => bookmark.postid === postid)) {
      console.log("Removing bookmark...");
      updatedBookmarks = storedBookmarks.filter((bookmark) => bookmark.postid !== postid);
    } else {
      console.log("Adding bookmark...");
      const newBookmark: BookmarkData = {
        postid,
        datetime: Date.now(),
        posttitle: posttitle || document.title,
        postlink: postlink || window.location.href,
      };
      updatedBookmarks = [...storedBookmarks, newBookmark];
    }

    // Update localStorage
    localStorage.setItem(bookmarkPath, JSON.stringify(updatedBookmarks));
    // Optionally, trigger a re-render or notify other components
  }
};

interface BookmarkToggleIconProps {
  postid: number; // Ensure this is a number, not a string
  headline?: string;
  link?: string;
}

const BookmarkToggleIcon: React.FC<BookmarkToggleIconProps> = ({ postid, headline, link }) => {
  console.log("bookmarkPath:", bookmarkPath); // Debug log

  const [isBookmarked, setIsBookmarked] = useState(false);

  useEffect(() => {
    if (!postid) return; // Guard clause

    const storedBookmarks: BookmarkData[] = JSON.parse(localStorage.getItem(bookmarkPath) || "[]");
    console.log("Stored bookmarks:", storedBookmarks); // Debug log
    console.log("Current postid:", postid); // Debug log
    const found = storedBookmarks.some((bookmark) => bookmark.postid === postid);
    console.log("Is bookmark found:", found); // Debug log
    setIsBookmarked(found);
  }, [postid, headline, link]);

  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleBookmarkOnArticlePage(bookmarkPath, e, postid, headline, link);
    setIsBookmarked((prev) => !prev);
  };

  return (
    <div
      id="bookmarktoggleicon"
      className="bookmarktoggleicon"
      onClick={handleToggle}
    >
      {isBookmarked ? <BookMarked size={24} className="tw-text-yellow-500" /> : <Bookmark size={24} />}
    </div>
  );
};

export { Bookmarks, BookmarkToggleIcon };
