// sessionUtils.tsimport { Storage } from 'aws-amplify';
import config from '../swift-config';

const currentConfig = config[window.location.hostname];
const COOKIE_NAME = 'swiftsessioncognito';
const COOKIE_DURATION_MINUTES = 365 * 24 * 60;

export function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
}

export function sessionOverride() {

  const currentSessionCount = getSessionCount();
  
  if (currentSessionCount) {
    console.log('Current session count:', currentSessionCount);
  } else {
    console.log('No session count found.');
  }

}

export function setCookie(name: string, value: string, minutes: number) {
  const date = new Date();
  date.setTime(date.getTime() + minutes * 60 * 1000);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
}

export function getSessionCount(): number {
  let sessionCount = 0;
  let lastVisitTimestamp = 0;
  const cookieValue = getCookie(COOKIE_NAME);

  if (cookieValue) {
    const [count, timestamp] = cookieValue.split('&');
    sessionCount = parseInt(count, 10) || 0;
    lastVisitTimestamp = parseInt(timestamp, 10) || 0;
  }

  if (Date.now() - lastVisitTimestamp > 30 * 60 * 1000) {
    sessionCount++;
  }

  setCookie(COOKIE_NAME, `${sessionCount}&${Date.now()}`, COOKIE_DURATION_MINUTES);
  return sessionCount;
}

export function setSessionCount(sessionCount: number): number {
  setCookie(COOKIE_NAME, `${sessionCount}&${Date.now()}`, COOKIE_DURATION_MINUTES);
  return sessionCount;
}

export async function fetchRegistrationWalls() {
  try {

    const pubID = currentConfig.Auth.pubID;
    const regwallContentURL = `https://swiftcom-regwall.s3.us-west-2.amazonaws.com/${pubID}-regwalls.json`;

    const fetchRegwallContent = async () => {
      try {
        const response = await fetch(regwallContentURL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) throw new Error("Network response was not ok");

        const data = await response.text();
        const decodedData = atob(data);
        const parsedData = JSON.parse(decodedData);

        const regwallContent = [
          {
            threshold: parsedData.firstRegwallSessionLimit,
            title: parsedData.firstRegwallName,
            message: parsedData.firstRegwallContent,
            active: parsedData.firstRegwallActive,
            dismissable: parsedData.firstRegwallIsDismissable,
          },
          {
            threshold: parsedData.secondRegwallSessionLimit,
            title: parsedData.secondRegwallName,
            message: parsedData.secondRegwallContent,
            active: parsedData.secondRegwallActive,
            dismissable: parsedData.secondRegwallIsDismissable,
          },
          {
            threshold: parsedData.thirdRegwallSessionLimit,
            title: parsedData.thirdRegwallName,
            message: parsedData.thirdRegwallContent,
            active: parsedData.thirdRegwallActive,
            dismissable: parsedData.thirdRegwallIsDismissable,
          }
        ];
        
        return regwallContent;
      } catch (error) {
        console.error("Fetch error:", error);
      }
    }
    return fetchRegwallContent();
  }
  catch (error) {
    console.error("Fetch error:", error);
  }
}
