// src/components/Profile.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Auth, Hub } from 'aws-amplify';
import ChangePassword from './ChangePassword';
import UpdateEmail from './UpdateEmail';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Taxonomies from './Taxonomies';
import { Bookmarks } from './Bookmarks';
import { PageviewHistory } from './PageviewHistory';
// import KeyPressExample from "./KeyPress";

const Profile: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const revocationHandled = useRef(false); // Ref to track if revocation is already handled
  
  const [name, setName] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [zipCode, setZipCode] = useState('');

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState('');
  const [showUpdateEmail, setShowUpdateEmail] = useState(false);
  const [updateEmailMessage, setUpdateEmailMessage] = useState('');
  const [showUpdateProfile, setShowUpdateProfile] = useState(false); // New state for profile update section
  const [updateProfileMessage, setUpdateProfileMessage] = useState(''); // New state for profile update message


  useEffect(() => {
    const hash = window.location.hash; // Get the hash from the URL
    if (hash) {
      const target = document.querySelector(hash);
      if (target) {
        // Scroll to the target smoothly
        target.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        // Optional: Add a delay if the element might not exist yet
        setTimeout(() => {
          const delayedTarget = document.querySelector(hash);
          if (delayedTarget) {
            delayedTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100); // Adjust delay as necessary
      }
    }
  }, []); // Runs once on component mount
  
  const handleRevocation = async () => {
    if (revocationHandled.current) return; // Exit if already handled
    revocationHandled.current = true;

    console.log('Access token revoked or expired. Logging out user.');
    setIsLoggedIn(false);
    clearUserAttributes();
    try {
      await Auth.signOut();
      alert('Your session has expired. Please sign in again.');
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  };
  // Clear user attributes state
  const clearUserAttributes = () => {
    setName('');
    setStreet('');
    setCity('');
    setState('');
    setCountry('');
    setZipCode('');
  };



  const handlePasswordSuccess = () => {
    setShowChangePassword(false);
    setPasswordMessage('Password changed successfully!');
  };

  const handlePasswordFailure = (message: string) => {
    setPasswordMessage(message);
  };

  const handleUpdateEmailSuccess = () => {
    setUpdateEmailMessage('Email updated successfully!');
  };

  const handleUpdateEmailFailure = (message: string) => {
    setUpdateEmailMessage(message);
  };

  useEffect(() => {
    // if the user is logged in
    if (isLoggedIn) {
      fetchUserAttributes();
    }

    const listener = (data: { payload: { event: string } }) => {
      if (data.payload.data?.code === 'NotAuthorizedException') {
        console.log('Not authorized: Access Token revoked.');
        handleRevocation();
      }
      switch (data.payload.event) {
        case 'signIn':
          setIsLoggedIn(true);
          fetchUserAttributes();
          break;
        case 'signOut':
          setIsLoggedIn(false);
          break;
        case 'refreshFailed':
          console.log('Refresh failed.');
          setIsLoggedIn(false);
          handleRevocation();
          break;
        case 'NotAuthorizedException':
          console.log('Not authorized.'); // Access Token revoked, possible admin logout
          setIsLoggedIn(false);
          handleRevocation();
          break;
        default:
          break;
      }
    };


    Hub.listen('auth', listener);
    
    // Initial token validation on page load
    
  // Initial token validation on page load
  const validateUserSession = async () => {
    try {
      // Fetch authenticated user to validate the session with Cognito
      const user = await Auth.currentAuthenticatedUser(); 
      console.log('User session is valid:', user);
      setIsLoggedIn(true);
      fetchUserAttributes(); // Fetch attributes only if Cognito validates the token
    } catch (error) {
      console.error('No current user or invalid session:', error);
      setIsLoggedIn(false);
      clearUserAttributes();
    }
  };

    validateUserSession();

    return () => {
      Hub.remove('auth', listener);
    };
  }, [isLoggedIn]);

  const fetchUserAttributes = async () => {
    try {
      // Force token refresh by calling currentSession
      const session = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser(); // Validate the user after token refresh
      console.log('Session refreshed:', session);
  
      const attributes = await Auth.userAttributes(user);
      const nameAttr = attributes.find(attr => attr.Name === 'name');
      const addressAttr = attributes.find(attr => attr.Name === 'address');
  
      setName(nameAttr ? nameAttr.Value : '');
  
      if (addressAttr) {
        const addressValue = JSON.parse(addressAttr.Value);
        setStreet(addressValue.street || '');
        setCity(addressValue.city || '');
        setState(addressValue.state || '');
        setCountry(addressValue.country || '');
        setZipCode(addressValue.postal_code || '');
      }
    } catch (error) {
      console.error('Error fetching user attributes:', error);
      if (error.name === 'NotAuthorizedException') {
        handleRevocation();
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const user = await Auth.currentAuthenticatedUser();
      const address = JSON.stringify({
        street,
        city,
        state,
        country,
        postal_code: zipCode,
      });

      await Auth.updateUserAttributes(user, {
        name,
        address,
      });

      setUpdateProfileMessage('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      setUpdateProfileMessage('Failed to update profile. Please try again.');
    }
  };

  if (!isLoggedIn) {
    return null;
  }

  const signOut = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    event.preventDefault();
    try {
      await Auth.signOut();
      setIsLoggedIn(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  return (
    <div className="tw-max-w-2xl tw-mx-auto tw-space-y-8">
      <Taxonomies />
      <Bookmarks />
      <PageviewHistory />
      {/* Update Profile Section */}
      <div className="tw-bg-white tw-p-8 tw-rounded tw-shadow">
        <div
          className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer"
          onClick={() => {
            setShowUpdateProfile(!showUpdateProfile);
            setUpdateProfileMessage('');
          }}
        >
          <h2 className="tw-text-2xl tw-font-bold">User Profile</h2>
          {showUpdateProfile ? (
            <ChevronUp className="tw-text-gray-600" />
          ) : (
            <ChevronDown className="tw-text-gray-600" />
          )}
        </div>
        {showUpdateProfile && (
          <form onSubmit={handleSubmit}>
            <div className="tw-mb-4">
              <label htmlFor="name" className="tw-block tw-mb-2">Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded"
                required
              />
            </div>
            <div className="tw-mb-4">
              <label htmlFor="street" className="tw-block tw-mb-2">Street</label>
              <input
                type="text"
                id="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded"
              />
            </div>
            <div className="tw-mb-4">
              <label htmlFor="city" className="tw-block tw-mb-2">City</label>
              <input
                type="text"
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded"
              />
            </div>
            <div className="tw-mb-4">
              <label htmlFor="state" className="tw-block tw-mb-2">State</label>
              <input
                type="text"
                id="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded"
              />
            </div>
            <div className="tw-mb-4">
              <label htmlFor="country" className="tw-block tw-mb-2">Country</label>
              <input
                type="text"
                id="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded"
              />
            </div>
            <div className="tw-mb-4">
              <label htmlFor="zipCode" className="tw-block tw-mb-2">Zip Code</label>
              <input
                type="text"
                id="zipCode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="tw-w-full tw-px-3 tw-py-2 tw-border tw-rounded"
              />
            </div>
            <button type="submit" className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded hover:tw-bg-blue-600">
              Update Profile
            </button>
          </form>
        )}
        {updateProfileMessage && (
          <div
            className={`tw-mt-4 tw-p-3 tw-rounded ${
              updateProfileMessage.includes('successfully')
                ? 'tw-bg-green-100 tw-text-green-700'
                : 'tw-bg-red-100 tw-text-red-700'
            }`}
          >
            {updateProfileMessage}
          </div>
        )}
      </div>

      {/* Update Email Section */}
      <div className="tw-bg-white tw-p-8 tw-rounded tw-shadow">
        <div
          className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer"
          onClick={() => {
            setShowUpdateEmail(!showUpdateEmail);
            setUpdateEmailMessage('');
          }}
        >
          <h2 className="tw-text-2xl tw-font-bold">Update Your Email Address</h2>
          {showUpdateEmail ? (
            <ChevronUp className="tw-text-gray-600" />
          ) : (
            <ChevronDown className="tw-text-gray-600" />
          )}
        </div>
        {showUpdateEmail && (
          <UpdateEmail
            onSuccess={handleUpdateEmailSuccess}
            onFailure={handleUpdateEmailFailure}
          />
        )}
        {updateEmailMessage && (
          <div
            className={`tw-mt-4 tw-p-3 tw-rounded ${
              updateEmailMessage.includes('successfully')
                ? 'tw-bg-green-100 tw-text-green-700'
                : 'tw-bg-red-100 tw-text-red-700'
            }`}
          >
            {updateEmailMessage}
          </div>
        )}
      </div>

      {/* Account Security Section with Expand Toggle */}
      <div className="tw-bg-white tw-p-8 tw-rounded tw-shadow">
        <div
          className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer"
          onClick={() => {
            setShowChangePassword(!showChangePassword);
            setPasswordMessage('');
          }}
        >
          <h2 className="tw-text-2xl tw-font-bold">Change Your Password</h2>
          {showChangePassword ? (
            <ChevronUp className="tw-text-gray-600" />
          ) : (
            <ChevronDown className="tw-text-gray-600" />
          )}
        </div>
        {showChangePassword && (
          <ChangePassword
            onSuccess={handlePasswordSuccess}
            onFailure={handlePasswordFailure}
          />
        )}
        {passwordMessage && (
          <div
            className={`tw-mt-4 tw-p-3 tw-rounded ${
              passwordMessage.includes('successfully')
                ? 'tw-bg-green-100 tw-text-green-700'
                : 'tw-bg-red-100 tw-text-red-700'
            }`}
          >
            {passwordMessage}
          </div>
        )}
      </div>
      <div className="tw-flex tw-justify-center">
        <button
          onClick={signOut}
          className="tw-bg-transparent tw-text-black tw-px-4 tw-py-2 tw-rounded hover:tw-bg-primary"
        >
          Sign out
        </button>
      </div>
      
    </div>
  );
};

export default Profile;