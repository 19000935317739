// src/services/NotificationService.ts
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

class NotificationService {
  private messaging: Messaging;
  private firebaseConfig: FirebaseConfig = {
    apiKey: "AIzaSyA3ihf-hSpoyAhSB_D7_ov9VTM0E_vXl2o",
    authDomain: "summit-daily-fa136.firebaseapp.com",
    projectId: "summit-daily-fa136",
    storageBucket: "summit-daily-fa136.firebasestorage.app",
    messagingSenderId: "411987689200",
    appId: "1:411987689200:web:619e26bf38ad39c5338c35",
    measurementId: "G-3WKN249VTG"
  };

  constructor() {
    const firebaseApp = initializeApp(this.firebaseConfig);
    this.messaging = getMessaging(firebaseApp);
  }

  async registerServiceWorker(): Promise<void> {
    if ('serviceWorker' in navigator) {
      try {
        await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      } catch (error) {
        console.error('Service worker registration failed:', error);
      }
    }
  }

  async getExistingToken(): Promise<string | null> {
    await this.registerServiceWorker();
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      console.error('No service worker registration found.');
      return null;
    }
    
    const fcmToken = await getToken(this.messaging, {
      vapidKey: 'BOA3D8Cqcrbl8wj_Mu1hZ2fgX6JNTSxIMI-IefIx-iHRxdN3pV0BQvfje0ey6kIEPE7mandYLk2fym1VbuKw8mI',
      serviceWorkerRegistration: registration
    });
    
    return fcmToken;
  }
  
  async enableNotifications(): Promise<string | null> {
    // Only request permission if not already granted.
    if (Notification.permission !== 'granted') {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.log('Notification permission not granted.');
        return null;
      }
    } else {
      console.log('Notification permission already granted');
    }
    await this.registerServiceWorker();
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      console.error('No service worker registration found.');
      return null;
    }
    const fcmToken = await getToken(this.messaging, {
      vapidKey: 'BOA3D8Cqcrbl8wj_Mu1hZ2fgX6JNTSxIMI-IefIx-iHRxdN3pV0BQvfje0ey6kIEPE7mandYLk2fym1VbuKw8mI',
      serviceWorkerRegistration: registration
    });
    if (!fcmToken) {
      console.error('Failed to get FCM token');
      return null;
    }
    console.log('FCM token:', fcmToken);
    return fcmToken;
  }
}

export const notificationService = new NotificationService();