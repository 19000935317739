// src/components/NotificationToggle.tsx
import React from 'react';
import { Bell, BellOff } from 'lucide-react';
import { useNotifications } from '../hooks/useNotifications';

export const NotificationToggle: React.FC = () => {
  const { isEnabled, token, error, enableNotifications, loading } = useNotifications();

  return (
    <div className="flex flex-col gap-2">
      {isEnabled ? (
        <div className="flex items-center text-green-600">
          <Bell className="w-5 h-5 mr-2" />
          <span>Notifications enabled</span>
          {token && (
            <span
              className="text-xs text-gray-500 ml-2 cursor-pointer"
              onClick={() => navigator.clipboard.writeText(token)}
            >
              {token}
            </span>
          )}
        </div>
      ) : (
        <>
          <div className="flex items-center text-gray-500">
            <BellOff className="w-5 h-5 mr-2" />
            <span>Notifications disabled</span>
          </div>
          <button
            onClick={enableNotifications}
            disabled={loading}
            className={`tw-bg-blue-500 tw-text-white tw-py-2 tw-px-4 tw-rounded hover:tw-bg-blue-600 ${loading ? 'tw-opacity-50' : ''}`}
          >
            {loading ? 'Enabling...' : 'Enable Notifications'}
          </button>
        </>
      )}
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};