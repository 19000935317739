import React, { useEffect, useState } from "react";
import { Authenticator, ThemeProvider, View  } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify"; // Import Auth from AWS Amplify
import "@aws-amplify/ui-react/styles.css"; // Import Amplify UI styles
import styles from "./styles.module.css"; // Import custom styles
import { Hub } from "aws-amplify"; // Import Hub from AWS Amplify
import { setCookie } from "../utils/sessionUtils"; // Import setCookie function
import { I18n } from "aws-amplify";

// import './styles.module.css'; // Import custom styles (ensure this file exists)
// import DeleteProfile from './DeleteProfile';
// import { getCurrentThemeColors } from "../utils/theme";

const COOKIE_NAME_LOGGED_IN = "swiftCognitoHasLoggedIn";
let creatingAccount = false;

// Customize the messages
I18n.putVocabulariesForLanguage("en", {
  "We Texted You": "We Emailed You",
  "Your code is on the way": "Your link is on the way",
  "To log in, enter the code we emailed to": "To log in, click the link we emailed to",
});

// Reset Password
// Enter your username
I18n.putVocabulariesForLanguage("en", {
    "Enter your username": "Enter your email",
    "Reset password": "Reset password",
    "Username": "Email",
    "Send Code": "Send link",
});

const AuthComponent: React.FC = () => {

    const [isLoading, setIsLoading] = useState(false); // Add loading state

    
  useEffect(() => {
    // START If Forgot Password link is clicked, show the custom form and get the code from the URL that we sent via email. Not standard Amplify behavior.
    
    const queryParams = new URLSearchParams(window.location.search);
    // const username = queryParams.get("username");
    const forgot_password_code = queryParams.get("forgot_password_code");
    let forgot_password_email = queryParams.get("email");
    forgot_password_email = forgot_password_email ? forgot_password_email.replace(/ /g, "+") : null;

    const signup_code = queryParams.get("signup_code");
    let signup_email = queryParams.get("signup_email");
    signup_email = signup_email ? signup_email.replace(/ /g, "+") : null;

    // console log all the params if they exist
    console.log("forgot_password_code:", forgot_password_code);
    console.log("forgot_password_email:", forgot_password_email);
    console.log("signup_code:", signup_code);
    console.log("signup_email:", signup_email);

    // doing reset password
    if (forgot_password_code && forgot_password_email) {
        setIsLoading(true); // Show loading indicator

      // show the custom form and hide the <Authenticator> component formresetpassword
      document.getElementById("formresetpassword")!.classList.remove("hidden");
      document.getElementById("authenticator")!.classList.add("hidden");

      // add event listener for the password reset confirm button
      document.getElementById("passwordResetConfirm")!.addEventListener("click", async (event) => {
        event.preventDefault();
        const forminputnewpassword = (document.getElementById("forminputnewpassword") as HTMLInputElement)!.value;
        const forminputnewpasswordconfirm = (document.getElementById(
          "forminputnewpasswordconfirm"
        ) as HTMLInputElement)!.value;
        const message = document.getElementById("resetPasswordMessage")!;
        if (forminputnewpassword !== forminputnewpasswordconfirm) {
          message.innerHTML = "Passwords do not match";
          return;
        }
        if (forminputnewpassword.length < 6) {
          message.innerHTML = "Password must be at least 6 characters";
          return;
        }

        Auth.forgotPasswordSubmit(forgot_password_email, forgot_password_code, forminputnewpassword)
          .then((data) => {
            message.innerHTML = "Password reset complete. Logging in...";
            console.log("Password reset successful");
            console.log(data);
            // log the user in with their forgot_password_email and new password
            Auth.signIn(forgot_password_email, forminputnewpassword)
              .then(() => {
                message.innerHTML = "Logged in successfully";
                console.log("User logged in");
                setCookie(COOKIE_NAME_LOGGED_IN, "true", 525600); // Set cookie for 1 year
              })
              .catch((err) => {
                console.error("Error logging in user", err);
              });
            // refresh this page without the query params, so we dont reset the password again
            window.location.href = window.location.href.split("?")[0];
            // TODO: redirect to the article they were reading before clicking sign in or sign up.
            console.log("redirecting to home page or where they were before");
          })
          .catch((err) => {
            if (err.code === "UserNotConfirmedException") {
              message.innerHTML =
                "Email address not confirmed. Please click the link in the email we just sent you, then you can use the password reset feature.";
              Auth.resendSignUp(forgot_password_email);
            } else if (err.code === "UserNotFoundException") {
              message.innerHTML = "Account not found under email: " + forgot_password_email + ", please try another email or signup.";            
              document.getElementById("formresetpassword")!.classList.add("hidden");
              document.getElementById("authenticator")!.classList.remove("hidden");
            } else if (err.code === "ExpiredCodeException") {
              message.innerHTML = "Code has expired. Please request a new code by clicking the 'Forgot Password' link.";
              document.getElementById("formresetpassword")!.classList.add("hidden");
              document.getElementById("authenticator")!.classList.remove("hidden");
            } else {
              message.innerHTML = "An error occurred. Please try again later.";
              console.error(err.message);
              console.error(err);
            }
          })
          .finally(() => {
            setIsLoading(false); // Hide loading indicator
            window.history.replaceState({}, document.title, window.location.pathname);
          });
      });
      // END If Forgot Password link is clicked, show the custom form and get the code from the URL that we sent via email. Not standard Amplify behavior
    }
    // START If sigup_code is in the URL, show the custom form and get the code from the URL that we sent via email. Not standard Amplify behavior.
    // https://dev.summitdaily.com/vite/?signup_code=985914&signup_email=mferrari@swiftcom.com
    else if (signup_code && signup_email) {
        setIsLoading(true); // Show loading indicator

        // hide the authenticator form
        document.getElementById("formresetpassword")!.classList.add("hidden");
        document.getElementById("authenticator")!.classList.remove("hidden");
      try {
        //   console.info(
        //     'Unencoded signup_email is ' +
        //       decodeURIComponent(signup_email) +
        //       ' and the charReplaceEmailSignUp email is ' +
        //       signup_email
        //   );
          console.info('The confirmation code is: ' + signup_code);
        
        Auth.confirmSignUp(signup_email, signup_code)
        .then(() => {
            console.info('Confirmed');
            // tell the user their account is confirmed and they can login
            //   this.confirmAccountConversion();
            const messagecheckyouremail = document.getElementById("messagecheckyouremail")!;
            messagecheckyouremail.innerHTML = "Your account is confirmed. You can now log in.";
            document.getElementById("messagecheckyouremail")!.classList.remove("hidden");
            document.getElementById("messagecheckyouremail")!.scrollIntoView();
            document.getElementById("authenticator")!.classList.remove("hidden");
        })
        .catch((error) => {
          console.error('We weren\'t able to confirm your account. Your link may have expired.', error);
          // tell the user we couldn't confirm their account.
          // check for expired code and resend the code
          if (error.code === 'ExpiredCodeException') {
            console.error('Code has expired. Resending code to ' + signup_email);
            // tell the user the code has expired and we just resent another code, please check your email
            Auth.resendSignUp(signup_email);
            const messagecheckyouremail = document.getElementById("messagecheckyouremail")!;
            messagecheckyouremail.innerHTML = "The link has expired. We've sent you a new link to confirm your account, and then sign in.";
            document.getElementById("messagecheckyouremail")!.classList.remove("hidden");
            document.getElementById("messagecheckyouremail")!.scrollIntoView();
            document.getElementById("authenticator")!.classList.add("hidden");
          }
        })
        .finally(() => {
            setIsLoading(false); // Hide loading indicator
            window.history.replaceState({}, document.title, window.location.pathname);
        });

      } catch (error) {
        console.error('We weren\'t able to confirm your account. Your link may have expired.', error);
        const messagecheckyouremail = document.getElementById("messagecheckyouremail")!;
        messagecheckyouremail.innerHTML = "We weren't able to confirm your account. Your link may have expired. <a href='/contact-us/'>Please email support</a>.";
        document.getElementById("messagecheckyouremail")!.classList.remove("hidden");
        document.getElementById("messagecheckyouremail")!.scrollIntoView();
      }
      // END If sigup_code is in the URL, show the custom form and get the code from the URL that we sent via email. Not standard Amplify behavior.
    } else {
      // Not a forgot password or signup code, show the standard Amplify form
      document.getElementById("formresetpassword")!.classList.add("hidden");
      document.getElementById("authenticator")!.classList.remove("hidden");
    }
  }, []);

  // const colors = getCurrentThemeColors();

  useEffect(() => {
    const listener = (data: { payload: { event: string } }) => {
      console.log("data.payload.event:", data.payload.event);
      console.log("data:", data);

      switch (data.payload.event) {
        case "signIn":
          setCookie(COOKIE_NAME_LOGGED_IN, "true", 525600); // Set cookie for 1 year
          break;
        case "signIn_failure":
          // check if we're creating a new account and return
          if (creatingAccount) {
            break;
          }
        //   let errormessage;
        //   // if we get an error, show the error message
        //   if (data.payload.data && data.payload.data.message) {
        //     errormessage = data.payload.data.message;
        //     console.error("Error signing in:", data.payload.data.message);
        //     document.getElementById("messagecheckyouremail")!.classList.remove("hidden");
        //     // document.getElementById("authenticator")!.style.display = "none";
        //     document.getElementById("messagecheckyouremail")!.scrollIntoView();
        //     // resend the signup confirmation email
        //     if (data.payload.data.message === "User is not confirmed.") {
        //       let email = data.payload.message;
        //       email = email.split(" ")[0];
        //       Auth.resendSignUp(email as string);
        //       errormessage =
        //         "Email address not confirmed. Please click the link in the email we just sent you, then you can use the password reset feature.";
        //     }
        //     document.getElementById("messagecheckyouremail")!.innerHTML = errormessage;
        //     return;
        //   }
          break;
        case "signOut":
          setCookie(COOKIE_NAME_LOGGED_IN, "false", 525600); // Update cookie
          break;
        case "forgotPassword":
          // hide the <Authenticator> component formresetpassword
          document.getElementById("messagecheckyouremail")!.classList.remove("hidden");
          document.getElementById("authenticator")!.classList.add("hidden");
          document.getElementById("messagecheckyouremail")!.scrollIntoView();
          break;
        // case "confirmSignUp":
        //   // confirm the signup with the code from the URL
        //   Auth.confirmSignUp(email as string, code as string)
        //     .then(() => {
        //       console.log("Signup confirmed successfully");
        //       // tell the user their account is confirmed and they can login
        //       //   this.confirmAccountConversion();
        //     })
        //     .catch((error) => {
        //       console.error("We weren't able to confirm your account. Your link may have expired.", error);
        //       // tell the user we couldn't confirm their account.
        //       // check for expired code and resend the code
        //       if (error.code === "ExpiredCodeException") {
        //         console.error("Code has expired. Resending code to " + email);
        //         // tell the user the code has expired and we just resent another code, please check your email
        //         Auth.resendSignUp(email as string);
        //       }
        //     });
        //   break;
        case "signUp":
          creatingAccount = true;
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", listener);

    return () => {
      Hub.remove("auth", listener);
    };
  }, []);

  // Optionally, define a custom theme if needed
  const customTheme = {
    name: "Custom Authenticator Theme",
    tokens: {
      colors: {
        brand: {
          primary: {
            "10": "var(--primary-color)",
            "80": "var(--secondary-color)",
            "100": "var(--white)", // You can use other CSS variables as needed
          },
          secondary: {
            "10": "var(--secondary-color)",
            "80": "#000000",
            "100": "#ffcccc",
          },
        },
      },
      components: {
        authenticator: {
          router: {
            boxShadow: "var(--amplify-components-authenticator-router-box-shadow)",
            borderWidth: "var(--amplify-components-authenticator-router-border-width)",
          },
          form: {
            padding: "var(--amplify-components-authenticator-form-padding)",
          },
        },
        button: {
          primary: {
            backgroundColor: "var(--amplify-components-button-primary-background-color)",
            _hover: {
              backgroundColor: "var(--amplify-components-button-primary-hover-background-color)",
            },
            _active: {
              backgroundColor: "var(--amplify-components-button-primary-active-background-color)",
            },
          },
          link: {
            color: "var(--amplify-components-button-link-color)",
            _hover: {
              color: "var(--amplify-components-link-hover-color)",
            },
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: "var(--amplify-components-fieldcontrol-focus-box-shadow)",
          },
        },
        tabs: {
          item: {
            color: "var(--amplify-components-tabs-item-color)",
            _active: {
              borderColor: "var(--amplify-components-tabs-item-active-border-color)",
              color: "var(--amplify-components-tabs-item-active-color)",
            },
            _hover: {
              color: "var(--amplify-components-tabs-item-hover-color)",
            },
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={customTheme}>
      <View padding="medium">
        <div id="messagecheckyouremail" className="tw-hidden tw-mx-auto tw-text-center">
          <h5>Please click on the link in your email to set your new password.</h5>
        </div>
        <div id="formresetpassword" className="tw-hidden tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-screen">
          <form className="tw-bg-white tw-p-6 tw-rounded tw-shadow-md tw-w-full tw-max-w-sm">
            <span id="resetPasswordMessage" className="tw-text-red-500 tw-mb-4 tw-block"></span>
            <p className="tw-mb-4">Enter your new password</p>
            <label htmlFor="password" className="tw-block tw-mb-2">
              New Password
            </label>
            <br></br>
            <input type="password" id="forminputnewpassword" className="tw-border tw-p-2 tw-mb-4 tw-w-full" />
            <label htmlFor="forminputnewpasswordconfirm" className="tw-block tw-mb-2">
              Confirm New Password
            </label>
            <input type="password" id="forminputnewpasswordconfirm" className="tw-border tw-p-2 tw-mb-4 tw-w-full" />
            <button id="passwordResetConfirm" className="tw-bg-blue-500 tw-text-white tw-p-2 tw-rounded tw-w-full">
              Reset Password
            </button>
          </form>
        </div>

        {isLoading && <div>One moment...</div>} {/* Loading indicator */}

        <div id="authenticator" className="">
          <Authenticator
            className={styles.authenticator}
            formFields={{
              signIn: {
                username: {
                  labelHidden: false, // Show the label
                  placeholder: "Enter your email",
                  label: "Email",
                },
              },
              signUp: {
                username: {
                  labelHidden: false,
                  placeholder: "Enter your email",
                  label: "Email",
                },
              },
            }}
          >
            {() => (
              <div>
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
                {/* <p>Welcome {user?.username}</p> */}
                {/* <button onClick={signOut}>Sign out</button> */}
                {/* button to delete the user profile with confirm dialog */}
                {/* <DeleteProfile userId={user?.username ?? ''} /> */}
                {/* <div style={{ backgroundColor: colors.brand.primary['10'], color: colors.brand.secondary['100'] }}> */}
                {/* Your component content */}
                {/* </div> */}
              </div>
            )}
          </Authenticator>
        </div>
      </View>
    </ThemeProvider>
  );
};

export default AuthComponent;
