import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import { getSessionCount, fetchRegistrationWalls, setSessionCount } from "../utils/sessionUtils";
import { Auth } from "aws-amplify";
import ReactGA from "react-ga4";


const PaywallModal: React.FC = () => {
  interface RegistrationWall {
    threshold: number;
    title: string;
    message: string;
    active: boolean;
    dismissable: boolean;
  }

  const [isOpen, setIsOpen] = useState(false);
  const [wallContent, setWallContent] = useState<RegistrationWall | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [isDismissable, setIsDismissable] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch {
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    if (isAuthenticated) return;

    const checkSessionAndShowModal = async () => {
      try {
        const sessionCount = getSessionCount();
        const regWalls = await fetchRegistrationWalls();

        function getActiveWall(sessionCount: number): RegistrationWall | null {
          const activeWalls = regWalls?.filter((wall) => wall.active).sort((a, b) => a.threshold - b.threshold);

          let wallToShow = null;
          if (activeWalls) {
            for (const wall of activeWalls) {
              if (sessionCount >= wall.threshold) {
                wallToShow = wall;
              } else {
                break;
              }
            }
          }
          return wallToShow;
        }

        const activeWall = getActiveWall(sessionCount);

        if (activeWall) {
          setWallContent({ ...activeWall });
          setIsDismissable(activeWall.dismissable);
          setIsOpen(true);

          // TODO
          // if (window.location.hostname.includes('dev.') || window.location.hostname.includes('local')) {
          //   gtag('set', 'user_properties', {
          //       dev_testdimension: 'Dev site signed out'
          //   });
          // }
          // // send the sub as the userID to GA4
          // gtag('config', tagID, {
          //   'user_id': sub
          // });
          ReactGA.event({
            category: "devtest",
            action: "show_registration_wall",
            label: "which_name", // optional
            // value: 99, // optional, must be a number
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          });


        }
      } catch (error) {
        console.error("Error fetching registration wall: ", error);
      }
    };

    checkSessionAndShowModal();

    const handleKeyDown = async (event: KeyboardEvent) => {
      const regWalls = await fetchRegistrationWalls();

      if (event.metaKey && event.ctrlKey && ["0", "1", "2", "3"].includes(event.key)) {
        if (event.key === "0") {
          setSessionCount(0);
          window.location.reload();
        } else {
          const selectedWall = regWalls ? regWalls[Number(event.key) - 1] : undefined;
          if (selectedWall) {
            setSessionCount(selectedWall.threshold);
            setWallContent(selectedWall);
            setIsDismissable(selectedWall.dismissable);
            window.location.reload();
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown.bind(null));

    return () => {
      window.removeEventListener("keydown", handleKeyDown.bind(null));
    };
  }, [isAuthenticated, isDismissable]);

  useEffect(() => {
    if (isOpen && !isDismissable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen, isDismissable]);

  const closeModal = () => setIsOpen(false);

  const exportLists = async () => {
    try {
      const regWalls = await fetchRegistrationWalls();
      const sessionCount = getSessionCount();
  
      const data = {
        registrationWalls: regWalls,
        sessionCount: sessionCount,
      };
  
      const blob = new Blob([JSON.stringify(data, null, 2)], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "exportedLists.json";
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting lists: ", error);
    }
  };

  if (!isOpen || !wallContent || isAuthenticated) return null;

  return (
    <div
      className="tw-fixed tw-backdrop tw-inset-0 tw-bg-black tw-bg-opacity-85 backdrop-opacity-85 tw-flex tw-items-center tw-justify-center tw-z-50"
      onClick={(e) => {
        if (isDismissable && (e.target as HTMLElement).classList.contains("tw-backdrop")) {
          closeModal();
        }
      }}
    >
      <div className="tw-bg-white tw-p-8 tw-rounded-lg tw-max-w-md tw-w-full tw-relative">
        {isDismissable && (
          <button onClick={closeModal} className="tw-absolute tw-right-4 tw-top-4 tw-bg-transparent">
            <X className="tw-bg-transparent" />
          </button>
        )}
        <div className="tw-text-pretty tw-text-center" dangerouslySetInnerHTML={{ __html: wallContent.message }} />
        <button
          onClick={() => (window.location.href = "/authentication")}
          className="tw-w-full tw-bg-blue-500 hover:tw-bg-blue-600 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded"
        >
          Sign In or Sign Up to Continue
        </button>
        <button
          onClick={exportLists}
          className="tw-w-full tw-bg-green-500 hover:tw-bg-green-600 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-mt-4"
        >
          Export Lists
        </button>
      </div>
    </div>
  );
};

export default PaywallModal;
