import "./polyfills"; // This should be the first import
import React from "react";
import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import AuthComponent from "./components/AuthComponent";
import LogoutLink from "./components/LogoutLink.tsx";
import Newsletter from "./components/Newsletter";
import GoogleMapsAddressForm from './components/GoogleMapsAddressForm.tsx';
import Profile from "./components/Profile";
import { Bookmarks, BookmarkToggleIcon } from "./components/Bookmarks";
import { PageviewHistory, PageviewTracker } from "./components/PageviewHistory"; // Import PageviewTracker
import Taxonomies from "./components/Taxonomies";
import PaywallModal from "./components/PaywallModal";
// import NewsArticle from "./components/NewsArticle";
// import Polls from "./components/Polls";
// import NewsArticleList from "./components/NewsArticleList";
// import HeroModule from "./components/HeroModule";
// import BreakingNews from "./components/BreakingNews";
// import ImageGallery from "./components/ImageGallery";
// import InAppNotifications from "./components/InAppNotifications";
// import NewsletterOptinCard from "./components/NewsletterOptinCard";
// import NewsletterPage from "./components/NewsletterPage";

import ReactGA from "react-ga4";

import config from "./swift-config.tsx";

// import NotificationSetup from "./components/NotificationSetup.tsx";
import "./index.css";
import "@aws-amplify/ui-react/styles.css";

// import { notificationService } from './services/NotificationService';
// import { NotificationToggle } from "./components/NotificationToggle";

// Register service worker as early as possible
// if ('serviceWorker' in navigator) {
  //   window.addEventListener('load', () => {
    //     notificationService.registerServiceWorker()
    //       .then(registration => {
      //         console.log('Service Worker registered with scope:', registration?.scope);
      //       })
      //       .catch(error => {
        //         console.error('Service Worker registration failed:', error);
        //       });
        //   });
        // }
        
        let currentConfig; //, pubID;
        if (window.location.hostname === "localhost") {
          console.log("Using localhost config");
          currentConfig = config["dev.summitdaily.com"];
        } else {
          console.log("Using config for", window.location.hostname);
          currentConfig = config[window.location.hostname];
        }
        
        
        Amplify.configure(currentConfig);
        
        ReactGA.initialize([
          {
            trackingId: currentConfig?.Google?.Analytics?.trackingId || '',
            // gaOptions: {...}, // optional
            // gtagOptions: {...}, // optional
          },
          {
            trackingId: currentConfig?.Google?.Analytics?.rollupId || '',
          },
        ]);

        // Send pageview with a custom path
        // ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });

        // // Send a custom event
        // ReactGA.event({
        //   category: "your category",
        //   action: "your action",
        //   label: "your label", // optional
        //   value: 99, // optional, must be a number
        //   nonInteraction: true, // optional, true/false
        //   transport: "xhr", // optional, beacon/xhr/image
        // });


        /**
         * Renders a React component into a specified DOM element.
        *
        * @param Component - The React component to render.
        * @param elementId - The ID of the DOM element where the component should be rendered.
        * @param withAmplifyProvider - Optional. If true, wraps the component with an AmplifyProvider. Defaults to true.
        *
        * The component is rendered differently based on the environment:
        * - In production, the component is rendered directly.
        * - In development, the component is wrapped with React.StrictMode.
        */
const renderComponent = (
  Component: React.ComponentType,
  elementId: string,
  withAmplifyProvider = true
) => {
  const element = document.getElementById(elementId);
  if (element) {
    const isProduction = process.env.NODE_ENV === "production";
    ReactDOM.createRoot(element).render(
      withAmplifyProvider ? (
        <AmplifyProvider>
          {isProduction ? <Component /> : <React.StrictMode><Component /></React.StrictMode>}
        </AmplifyProvider>
      ) : (
        <Component />
      )
    );
  }
};
// const renderComponent = (Component: React.ComponentType, elementId: string) => {
//   const element = document.getElementById(elementId);
//   if (element) {
//     const isProduction = process.env.NODE_ENV === "production";
//     ReactDOM.createRoot(element).render(
//       isProduction ? <AmplifyProvider><Component /></AmplifyProvider> : <React.StrictMode><AmplifyProvider><Component /></AmplifyProvider></React.StrictMode>
//     );
//   }
// };

const renderApp = () => {
  // renderComponent(NewsletterPage, "newsletterpage");
  renderComponent(AuthComponent, "authhere");
  renderComponent(PageviewTracker, "pageviewtracker");
  renderComponent(PaywallModal, "wallmodaldiv");
  renderComponent(LogoutLink, "swiftViteLogoutLinkDesktop");
  renderComponent(LogoutLink, "swiftViteLogoutLinkMobile");
  renderComponent(GoogleMapsAddressForm, "gmapshere");
  renderComponent(Profile, "profilehere");
  renderComponent(Newsletter, "newsletterhere");
  renderComponent(Bookmarks, "bookmarkshere");
  renderComponent(PageviewHistory, "pageviewhistoryhere");
  renderComponent(Taxonomies, "taxonomieshere");
  // renderComponent(InAppNotifications, "inappnotificationshere");
  // renderComponent(NotificationToggle, "notificationtogglehere");
  // renderComponent(NewsletterOptinCard, "newsletteroptincard");
  // renderComponent(BookmarkToggleIcon, "bookmarktoggleicon");
  // renderComponent(NotificationSetup, "webpushnotificationshere");
  // renderComponent(NewsArticle, "newsarticlehere");
  // renderComponent(NewsArticleList, "newsarticlelisthere");
  // renderComponent(Polls, "pollshere");
  // renderComponent(HeroModule, "heromodulehere");
  // renderComponent(BreakingNews, "breakingnewshere");
  // renderComponent(ImageGallery, "imagegalleryhere");
}
renderApp();


interface BookmarkToggleIconProps {
  postid: number; // Required
  headline?: string;
  link?: string;
}

const renderComponentClassName = (
  Component: React.ComponentType<BookmarkToggleIconProps>,
  className: string
) => {
  const elements = document.getElementsByClassName(className);
  if (elements.length > 0) {
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      // Extract data attributes
      const postidAttr = element.getAttribute('data-postid');
      const headline = element.getAttribute('data-headline') || undefined;
      const link = element.getAttribute('data-link') || undefined;

      // Ensure postid is present and is a number
      if (postidAttr) {
        const postid = Number(postidAttr);
        if (!isNaN(postid)) {
          ReactDOM.createRoot(element).render(
            <Component postid={postid} headline={headline} link={link} />
          );
        } else {
          console.error(`Invalid postid: ${postidAttr}`);
        }
      } else {
        console.error('postid is required for BookmarkToggleIcon');
      }
    }
  }
};
// render the next coponent with classnames instead of IDs
// renderComponent(BookmarkToggleIcon, "bookmarktoggleicon");
renderComponentClassName(BookmarkToggleIcon, "bookmarktoggleicon");

